import React from 'react';
import { BrowserRouter, Route, RouterProvider, Routes, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import HomePage from './pages/home';

import Mainlayout from './layout/mainlayout';


import { HeaderProvider } from './context/context';


function App() {

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route path='/' element={<Mainlayout />}>
          <Route index element={<HomePage />} />
        </Route>
      </Route>
    )
  )
  return (

    <>
      <HeaderProvider >
        <RouterProvider router={router} />
      </HeaderProvider>
    </>


  );
}

export default App;
