import React, { useEffect, useState, useContext, createContext } from 'react'

export const HeaderContext = createContext();

export const HeaderProvider = ({ children }) => {
    const [profile, setProfile] = useState('')
    return (
        <>
            <HeaderContext.Provider value={{profile, setProfile}}>
                {children}
            </HeaderContext.Provider>
        </>
    )

}