import React, { useState, useEffect, useContext } from 'react';
import '../header/header.scss';

import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavbarText,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu
} from 'reactstrap';

import brandlogo from '../../assets/images/logo.png';
import notification from '../../assets/images/notification.png';
import wallet from '../../assets/images/wallet.png';
import user_nav from '../../assets/images/user_nav.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CONST } from '../../_config';
import { HeaderContext } from '../../context/context';

function Header() {
    // header scroll start
    const [scroll, setScroll] = useState(false);
    const [userData, setUserData] = useState('')
    const navigate = useNavigate();
    //const [isConnected, setIsConnected] = useState(true)
    let location = useLocation();
    console.log(location.pathname)
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 50)
        })
        getuserDetails();
    }, [])
    // header scroll end
    const headers = {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem('authToken')}`
        }
    }
    const { profile, setProfile } = useContext(HeaderContext);

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const getuserDetails = async () => {
        try {
            let getdata = await axios.get(CONST.BACKEND_URL + 'user/get_user_details', headers);
            console.log(getdata)
            if (getdata.data.status == 200) {
                setUserData(getdata.data.data[0])
            }
        }
        catch (e) {
            // notification.error({ message: e.message })
        }
    }


    const logout = () => {
        sessionStorage.clear();
        navigate('/');
    }


    return (
        <>
            <header className={scroll ? "navbgset" : ""}>
                <Navbar color="light" light expand="xl" className="mynavbar px-xl-0" fixed='top'>
                    <div className='container-fluid'>
                        <NavbarBrand href="/">
                            <img src={brandlogo} className="logo" alt="brandlogo" />
                        </NavbarBrand>
                        <NavbarToggler onClick={toggle} />
                        <Collapse isOpen={isOpen} navbar>
                            <Nav className="mx-auto" navbar>
                                <NavItem>
                                    <Link className='nav-link' to="/market">Market</Link>
                                </NavItem>
                                <NavItem>
                                    <Link className='nav-link' to="/trade/BTC_INR">Trade</Link>
                                </NavItem>
                                <NavItem>
                                    <Link className='nav-link' to="/aboutus">Aboutus</Link>
                                </NavItem>
                            </Nav>

                            <NavbarText >
                                <Link to={location.pathname} className="btn btn-primary btnheaderIcon" role="button"> <span className="px-0"><img src={notification} /></span></Link>
                                <Link to={location.pathname} className="btn btn-primary btnheaderIcon ml-2" role="button"> <span className="px-0"><img src={wallet} /></span> </Link>

                                <UncontrolledDropdown className="position-relative d-inline-block ml-2">
                                    <DropdownToggle className="btn btn-primary btnheaderIcon w-auto">
                                        <img className="usernav" style={{ maxWidth: '35px', maxHeight: '35px' }} src={userData && userData.profile_img == '' ? user_nav : profile == '' ? userData?.profile_img : profile} />  <i className="bi bi-caret-down-fill ml-1 text-black"></i>
                                    </DropdownToggle>

                                    <DropdownMenu className="animate__animated animate__zoomIn dropdown-menu ">
                                        <ul id="usermenu" className="list-unstyled" >
                                            <li role="menuitem"><Link className="dropdown-item" to="/dashboard">Dashboard</Link></li>
                                            <li role="menuitem"><Link className="dropdown-item" to="/profile">Profile</Link></li>
                                            <li role="menuitem"><Link className="dropdown-item" to="#" onClick={logout}>Logout</Link></li>
                                        </ul>
                                    </DropdownMenu>
                                </UncontrolledDropdown>

                            </NavbarText>
                        </Collapse>
                    </div>
                </Navbar>
            </header>
        </>
    );

}

export default Header;