import React from 'react';
import { Outlet } from "react-router-dom";
import Header from "../component/header";
import Footer from "../component/footer";
import Header1 from '../component/header2'

const Mainlayout = () => {
    return (
        <>
            <Outlet />
        </>
    );
}

export default Mainlayout;