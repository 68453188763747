import React, { useState, useEffect } from 'react';
import './home.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Unity, useUnityContext } from "react-unity-webgl";
import { Triangle } from 'react-loader-spinner'
import LogoImg from '../../assets/images/metatron-multiverse.png';
import LoadingBar from 'react-top-loading-bar';
import { ProgressBar } from 'primereact/progressbar';

const HomePage = () => {

   
    let { unityProvider, loadingProgression, isLoaded, requestFullscreen, addEventListener } = useUnityContext({
        loaderUrl: "index/Build/Newfolder.loader.js",
        dataUrl: "index/Build/Newfolder.data",
        frameworkUrl: "index/Build/Newfolder.framework.js",
        codeUrl: "index/Build/Newfolder.wasm",

    });

    console.log(loadingProgression)
    useEffect(() => {
        addEventListener('change', handleClick);
      
    }, [isLoaded, loadingProgression])

    const handleClick = () => {
        requestFullscreen(true);
    }

    console.log(loadingProgression * 100)


    return (
        <div className="unityInstCnt">
            {/* <div className={isLoaded == false ? 'loader-react' : 'd-none'}>
                <ProgressBar value={loadingProgression} />
            </div> */}
            <div className={isLoaded == false ? 'loader-react' : 'd-none'}>
                
                    <img src={LogoImg}
                        height="80"
                        width="80"
                        color="#BD1722"
                        ariaLabel="triangle-loading"
                        wrapperStyle={{ color: '10px solid' }}
                        wrapperClassName=""
                        visible={true}
                    />
               
                <div className='loading'>
                    <p>Loading ...</p>
                </div>
            </div> 

           <Unity unityProvider={unityProvider}
            />
        </div>
    );

}

export default HomePage;